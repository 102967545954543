import React, { useEffect } from 'react'
import { Button, VStack, Stack, Flex, Text, Image } from '@chakra-ui/react'
import { useExperienceManager } from '@hooks'
import AssistUpgradePanel from '@handlers/sessions/components/shared/assist/upgrade-panel'
import {
  AssessmentSuggestionModule,
  WorksheetSuggestionModule,
  InterventionSuggestionModule
} from './suggestion-modules'
import {
  useSessionControllerGetSuggestedContent,
  useSessionControllerGetDemoSuggestedContent
} from '~/clinician-api'
import Waitlist from '../in-session-assist-waitlist-panel'
import Setup from './setup'
import TreatmentPlanGate from './treatment-plan-gate'
import { useTreatmentPlan } from '@hooks'
import { SettingsOutlined } from '@components/icons'
import { Patient } from '@handlers/sessions/types'
import { DisclaimerBanner } from '../shared/disclaimer-banner'

const SubtleLoading = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        animation: 'spin 1s linear infinite',
        margin: 'auto'
      }}
    >
      <style>
        {`
          @keyframes spin {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
          }
        `}
      </style>
      <mask id="path-1-inside-1_569_1716" fill="white">
        <path d="M19.0711 4.92893C17.6027 3.46052 15.7135 2.48568 13.6659 2.13974C11.6183 1.79379 9.51372 2.0939 7.64441 2.9984C5.77509 3.9029 4.23374 5.36693 3.2343 7.18726C2.23486 9.00758 1.82692 11.0939 2.06711 13.1566C2.3073 15.2193 3.1837 17.1561 4.57465 18.6981C5.96561 20.2401 7.80211 21.3108 9.82924 21.7615C11.8564 22.2123 13.9736 22.0209 15.8869 21.2137C17.8003 20.4065 19.4149 19.0237 20.5066 17.2572L18.8007 16.2029C17.9279 17.6151 16.6371 18.7207 15.1074 19.366C13.5778 20.0113 11.8852 20.1644 10.2646 19.804C8.64396 19.4436 7.17575 18.5876 6.06374 17.3548C4.95172 16.1221 4.25108 14.5737 4.05906 12.9247C3.86704 11.2756 4.19317 9.60768 4.99218 8.15241C5.79119 6.69713 7.02344 5.5267 8.51788 4.80359C10.0123 4.08047 11.6948 3.84055 13.3318 4.11712C14.9688 4.39369 16.4791 5.17303 17.653 6.34697L19.0711 4.92893Z" />
      </mask>
      <path
        d="M19.0711 4.92893C17.6027 3.46052 15.7135 2.48568 13.6659 2.13974C11.6183 1.79379 9.51372 2.0939 7.64441 2.9984C5.77509 3.9029 4.23374 5.36693 3.2343 7.18726C2.23486 9.00758 1.82692 11.0939 2.06711 13.1566C2.3073 15.2193 3.1837 17.1561 4.57465 18.6981C5.96561 20.2401 7.80211 21.3108 9.82924 21.7615C11.8564 22.2123 13.9736 22.0209 15.8869 21.2137C17.8003 20.4065 19.4149 19.0237 20.5066 17.2572L18.8007 16.2029C17.9279 17.6151 16.6371 18.7207 15.1074 19.366C13.5778 20.0113 11.8852 20.1644 10.2646 19.804C8.64396 19.4436 7.17575 18.5876 6.06374 17.3548C4.95172 16.1221 4.25108 14.5737 4.05906 12.9247C3.86704 11.2756 4.19317 9.60768 4.99218 8.15241C5.79119 6.69713 7.02344 5.5267 8.51788 4.80359C10.0123 4.08047 11.6948 3.84055 13.3318 4.11712C14.9688 4.39369 16.4791 5.17303 17.653 6.34697L19.0711 4.92893Z"
        fill="#C9C9C9"
        stroke="#C9C9C9"
        stroke-width="2"
        mask="url(#path-1-inside-1_569_1716)"
      />
    </svg>
  )
}

const SuggestionLoader = () => {
  return (
    <Stack spacing="0" gap="24px" flex="1">
      <VStack gap="16px" spacing="0" flex="1">
        <Flex justifyContent="center" width="170px" height="170px">
          <Image src="https://res.cloudinary.com/hellojoy/image/upload/v1739218692/coffee-cup-animation_1_l40krm.gif" />
        </Flex>
        <VStack spacing="0" gap="4px">
          <Text fontWeight="bold">Generating suggestions...</Text>
          <Text color="dark_gray">This could take a minute</Text>
        </VStack>
      </VStack>
    </Stack>
  )
}
interface AssistPanelProps {
  sessionId: string
  client: Patient
  toggleOverlay: () => void
  account: any
  session: any
}

const AssistPanel = ({
  sessionId,
  client,
  toggleOverlay,
  account,
  session
}: AssistPanelProps) => {
  const [showDemoLoading, setShowDemoLoading] = React.useState<boolean>(true)

  const {
    initialSelectedFocuses,
    treatmentPlan,
    canGenerateQuery,
    generateMutation
  } = useTreatmentPlan()

  const {
    data: suggestions,
    isLoading
  } = useSessionControllerGetSuggestedContent(sessionId, {
    query: {
      refetchInterval: data => {
        if (data?.isGenerationComplete === false) {
          return 5000
        }

        return false
      }
    }
  })

  const {
    isPlusPlanEnabled,
    isEvidenceBasedCareEnabled,
    isDemoClinician
  } = useExperienceManager()
  const showDemoExperience = (client.is_demo && isDemoClinician) ?? false

  const { data: demoData } = useSessionControllerGetDemoSuggestedContent(
    sessionId,
    { demoContext: 'post-session' },
    {
      query: {
        enabled: showDemoExperience
      }
    }
  )

  useEffect(() => {
    if (showDemoExperience) {
      setTimeout(() => {
        setShowDemoLoading(false)
      }, 2000)
    } else {
      setShowDemoLoading(false)
    }
  }, [showDemoExperience])

  const assessmentSuggestion = showDemoExperience
    ? demoData?.suggestedAssessments[0]
    : suggestions?.suggestedAssessments[0]
  const worksheetSuggestion = showDemoExperience
    ? demoData?.suggestedWorksheets[0]
    : suggestions?.suggestedWorksheets[0]
  const interventionSuggestion = showDemoExperience
    ? demoData?.suggestedInterventions[0]
    : suggestions?.suggestedInterventions[0]

  if (!isPlusPlanEnabled) {
    if (isEvidenceBasedCareEnabled) {
      return <Waitlist />
    }

    return <AssistUpgradePanel sessionId={session?.id} />
  }

  if (isLoading) {
    return (
      <Flex h="100%">
        <SubtleLoading />
      </Flex>
    )
  }

  if (suggestions?.isGenerationComplete === false || showDemoLoading) {
    return <SuggestionLoader />
  }

  if (!!treatmentPlan?.fileKey) {
    return (
      <TreatmentPlanGate
        treatmentPlan={treatmentPlan}
        canGenerateQuery={canGenerateQuery}
        generateMutation={generateMutation}
      />
    )
  }

  const hasFocusOfTreatment = initialSelectedFocuses?.filter(f => f).length > 0
  const hasAgeRange = !!client.age_range
  const hasProviderType = !!account?.license_level
  const hasTreatmentApproach =
    session?.treatmentApproaches && session.treatmentApproaches.length > 0
  const hasTreatmentSetting = !!account?.treatment_setting
  const hasHighRisk = !!client?.high_risk_timestamp

  if (
    !hasFocusOfTreatment ||
    !hasAgeRange ||
    !hasProviderType ||
    !hasTreatmentApproach ||
    !hasTreatmentSetting ||
    !hasHighRisk
  ) {
    return (
      <Setup
        toggleOverlay={toggleOverlay}
        client={client}
        hasFocusOfTreatment={hasFocusOfTreatment}
        hasAgeRange={!!client.age_range}
        hasProviderType={!!account?.license_level}
        hasTreatmentApproach={hasTreatmentApproach}
        hasTreatmentSetting={!!account?.treatment_setting}
        hasHighRisk={!!client?.high_risk_timestamp}
      />
    )
  }

  const showDisclaimer =
    (assessmentSuggestion || worksheetSuggestion || interventionSuggestion) &&
    isPlusPlanEnabled

  return (
    <Stack spacing="0" gap="32px" flex="1">
      {showDisclaimer && <DisclaimerBanner />}
      <AssessmentSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <WorksheetSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <InterventionSuggestionModule
        sessionId={sessionId}
        client={client}
        suggestions={suggestions}
        showDemoExperience={showDemoExperience}
        demoData={demoData}
      />
      <VStack spacing="0">
        <Button
          variant="outline"
          h="35px"
          borderColor="pale_gray"
          borderRadius="4px"
          textDecoration="none"
          pl="8px"
          pr="8px"
          onClick={toggleOverlay}
        >
          <Flex align="center" justify="space-between" gap="8px">
            <SettingsOutlined />
            <Text>Assist setup for {client.first_name}</Text>
          </Flex>
        </Button>
      </VStack>
    </Stack>
  )
}

export default AssistPanel
