import React, { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import { useStoreState } from 'easy-peasy'
import { useHistory } from 'react-router-dom'
import {
  Box,
  Text,
  Flex,
  Button,
  HStack,
  useToast,
  Tooltip,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  Divider,
  Alert,
  AlertDescription
} from '@chakra-ui/react'
import { endpoints } from '@api'
import { useAudioCapture } from '@hooks'
import {
  useSessionControllerPostSession,
  useSessionControllerPatchSession
} from '~/clinician-api'
import { throttle } from 'lodash'
import flagsmith from 'flagsmith'
import { FlagsmithFeatures } from '@constants/flagsmith'
import { useExperienceManager } from '@hooks'
import { Loading, SearchSelect } from '@components'
import { components } from 'react-select'
import {
  PersonAddIcon,
  NoHeadsetIcon,
  Headphones,
  InfoIcon,
  RadioSelectedIcon,
  SearchIcon
} from '@blueprinthq/joy'
import { MicrophoneSelector } from '@handlers/sessions/components/microphone-selector'
import { IconSelect } from '../../sessions/components'
import { trackEvent } from '@lib/clinician-tracking'
import { StoreModel } from 'src/store/types'
import { isMobile } from 'react-device-detect'
import { useExtension } from '@hooks'

interface Props {
  sessionId: string | undefined
  setSessionId: (sessionId: string) => void
}

const Control = (props: any) => (
  <components.Control {...props}>
    <SearchIcon name="search" style={{ marginLeft: 16, marginRight: 8 }} />
    {props.children}
  </components.Control>
)

const SelectDown = () => (
  <div
    style={{
      width: '1.5rem',
      height: '100%',
      fontSize: '1.25rem'
    }}
  >
    <svg
      viewBox="0 0 24 24"
      role="presentation"
      focusable="false"
      aria-hidden="true"
    >
      <path
        fill="currentColor"
        d="M16.59 8.59L12 13.17L7.41 8.59L6 10L12 16L18 10L16.59 8.59Z"
      ></path>
    </svg>
  </div>
)

const maxWidth = '750px'

export const SelectClient = ({ sessionId, setSessionId }: Props) => {
  const { isPlusPlanEnabled, isSafari, showRulaUI } = useExperienceManager()
  const { user } = useStoreState((state: StoreModel) => state.auth)
  const { settings } = useExtension()
  const [searchInput, setSearchInput] = useState('')
  const [sessionType, setSessionType] = useState<
    undefined | 'Telehealth' | 'In Person'
  >()
  const [recordingType, setRecordingType] = useState<undefined | string>()
  const [selectedClient, setSelectedClient] = useState<
    { value: string; label: string } | undefined
  >()
  const history = useHistory()
  const {
    testAudioInputs,
    hasMicAccess,
    startRecording,
    hasResolvedInputDevicePermissions,
    isUsingHeadphones,
    setIsUsingHeadphones,
    startContentShare,
    promptForDevicePermissions,
    permissionStatus
  } = useAudioCapture()
  const { mutateAsync: createSessionDraft } = useSessionControllerPostSession()
  const { mutateAsync: patchSession } = useSessionControllerPatchSession()
  const toast = useToast()

  const showSafariWarning =
    isSafari &&
    sessionType === 'Telehealth' &&
    !isMobile &&
    recordingType !== 'dictation'

  useEffect(() => {
    promptForDevicePermissions()
    testAudioInputs()
  }, [hasResolvedInputDevicePermissions])

  const startSession = async (clientId: string) => {
    const sessionDraft = await createSessionDraft(
      {
        data: {
          patientId: clientId,
          isExtension: false,
          isChime: false,
          extensionInitiatedFrom: null,
          isDictation: recordingType === 'dictation',
          resumeInProgress: true,
          isAssistEnabled: isPlusPlanEnabled,
          isTelehealth: sessionType === 'Telehealth'
        }
      },
      {
        onError: () => {
          console.error(
            `Blueprint: selectClient failed. Please ensure client with id ${clientId} is valid.`
          )
        }
      }
    )

    setSessionId(sessionDraft.id)
    return sessionDraft
  }

  const isInternalDemoOrg = flagsmith.hasFeature(
    FlagsmithFeatures.SHOW_IS_DEMO_CLIENTS_IN_START_SESSION
  )

  const {
    data: preferredClientList,
    isLoading: isPreferredClientListLoading
  } = useQuery(
    [endpoints.getPreferredClientList.getCacheId()],
    () =>
      endpoints.getPreferredClientList.request({
        clinicianId: user!.id
      }),
    {
      initialData: { clientsFromEHR: [], clientsWithSessionLastWeek: [] },
      select: (data: any) => ({
        clientsWithSessionLastWeek: data.clientsWithSessionLastWeek.filter(
          (n: any) => !n.isDemo || isInternalDemoOrg || showRulaUI
        )
      })
    }
  )

  const { data: defaultList, isLoading: isDefaultListLoading } = useQuery(
    [endpoints.getClientList.getCacheId()],
    () =>
      endpoints.getClientList.request({
        limit: 50,
        status: 'active',
        clinicianId: user!.id
      }),
    {
      initialData: [],
      select: (data: any) =>
        data.filter((n: any) => !n.isDemo || isInternalDemoOrg || showRulaUI)
    }
  )

  const { refetch, data: searchResults, isLoading: isSearchLoading } = useQuery(
    [
      endpoints.getPatientSearch.getCacheId(),
      'session_client_search',
      searchInput
    ],
    () =>
      endpoints.getPatientSearch.request({
        search: searchInput,
        include_discharged: false
      }),
    {
      enabled: false,
      initialData: [],
      select: (data: any) =>
        data
          .filter((n: any) => !n.is_demo || isInternalDemoOrg || showRulaUI)
          .map((n: any) => ({
            id: n.id,
            firstName: n.first_name,
            lastName: n.last_name
          }))
    }
  )

  const autoSelectClient = async (event: any) => {
    const sessionDraft = await startSession(event.data.clientId)

    return history.push(
      `/widget/patient/${event.data.clientId}/session/${sessionDraft.id}`
    )
  }

  useEffect(() => {
    window.addEventListener('message', event => {
      if (event.data.type === 'BP_SELECT_CLIENT') autoSelectClient(event)
    })

    return () => {
      window.removeEventListener('message', autoSelectClient)
    }
  }, [])

  useEffect(() => {
    if (searchInput?.length >= 1) {
      throttledSearch()
    }
  }, [searchInput])

  const throttledSearch = throttle(() => refetch(), 150)

  const handleStartRecording = async () => {
    if (!selectedClient)
      return toast({
        title: 'Error',
        description: 'Select a client to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    if (!hasMicAccess)
      return toast({
        title: 'Error',
        description: 'Enable you microphone to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    if (!sessionType)
      return toast({
        title: 'Error',
        description: 'Select a session type to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    if (!recordingType)
      return toast({
        title: 'Error',
        description: 'Select a recording type to start recording',
        status: 'error',
        isClosable: true,
        duration: 2000
      })

    await startRecording({
      noteType: 'soap',
      isTelehealth: sessionType === 'Telehealth',
      isUsingHeadphones: isUsingHeadphones
    })

    const isLastWeekClient = preferredClientList?.clientsWithSessionLastWeek?.some(
      (c: any) => c.id === selectedClient.value
    )

    if (isLastWeekClient) {
      trackEvent('Session -> Selected Client - Session Last Week')
    } else {
      trackEvent('Session -> Selected Client - All Clients')
    }

    trackEvent('Session -> Started Recording')

    history.push(
      `/widget/patient/${selectedClient.value}/${
        recordingType === 'live' ? '' : 'dictate/'
      }session/${sessionId}`
    )
  }

  const patients = searchInput?.length
    ? searchResults
    : defaultList.filter(
        // don't show clients that are in the preferred client list
        (p: any) =>
          !preferredClientList!.clientsWithSessionLastWeek
            .map((c: any) => c.id)
            .includes(p.id)
      )

  if (isDefaultListLoading || isPreferredClientListLoading) {
    return (
      <Box h="70vh" color="white">
        <Loading />
      </Box>
    )
  }

  const selectClientOptions = [
    {
      label: '',
      options: [
        {
          value: 'newClient',
          label: 'Add a new client'
        }
      ]
    },
    ...(preferredClientList?.clientsWithSessionLastWeek?.length && !searchInput
      ? [
          {
            label: 'Clients you met with last week',
            options: preferredClientList?.clientsWithSessionLastWeek.map(
              (c: any) => ({
                value: c.id,
                label: `${c.firstName} ${c.lastName}`
              })
            )
          }
        ]
      : []),
    {
      label:
        preferredClientList?.clientsWithSessionLastWeek?.length && !searchInput
          ? 'All clients'
          : '',
      options: patients.map((p: any) => ({
        value: p.id,
        label: `${p.firstName} ${p.lastName}`
      }))
    }
  ]

  return (
    <Flex
      h="100%"
      alignItems="center"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="space-between"
      id="bp-extension-loaded"
      p="xsmall"
    >
      <Box maxWidth={maxWidth} w="100%">
        <SearchSelect
          size="small"
          placeholder="Select or add a client"
          options={selectClientOptions}
          value={selectedClient}
          filterOption={null}
          onSearch={setSearchInput}
          onChange={async (option: any) => {
            if (option.value === 'newClient') {
              history.push('/start-session/new-client')
            } else {
              await startSession(option.value)
              setSelectedClient(option)
            }
          }}
          Components={{
            Option: (props: any) => (
              <components.Option {...props}>
                <Flex alignItems="center">
                  {props.data.value === 'newClient' && (
                    <Box mt="8px" mr="8px">
                      <PersonAddIcon />
                    </Box>
                  )}
                  {props.data.label}
                </Flex>
              </components.Option>
            ),
            Control
          }}
          errors={null}
        />
        <Box mt="xsmall" mb="xsmall">
          <MicrophoneSelector isWidget />
          {permissionStatus === 'denied' && (
            <Text color="white" mt="xsmall">
              Please allow access in your browser and refresh the page.
            </Text>
          )}
        </Box>
        <Box mb="xsmall" mt="xsmall" w="100%">
          <Menu matchWidth>
            <MenuButton
              px="small"
              borderRadius="md"
              borderWidth="1px"
              borderColor="light_gray"
              h="40px"
              w="100%"
            >
              <Flex justifyContent="space-between">
                {recordingType ? (
                  <Text>
                    {recordingType === 'live'
                      ? 'Live session recording'
                      : 'Dictate session summary'}
                  </Text>
                ) : (
                  <Text color="gray">Recording Type</Text>
                )}
                <SelectDown />
              </Flex>
            </MenuButton>
            <MenuList boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1),0 4px 11px rgba(0, 0, 0, 0.1)">
              <MenuItem
                _focus={{ bg: '#F5F5F7' }}
                onClick={async () => {
                  if (sessionId) {
                    await patchSession({
                      // @ts-ignore
                      id: sessionId,
                      data: { isDictation: false }
                    })
                  }
                  setRecordingType('live')
                }}
              >
                Live session recording
              </MenuItem>
              <MenuItem
                _focus={{ bg: '#F5F5F7' }}
                onClick={async () => {
                  if (sessionId) {
                    await patchSession({
                      // @ts-ignore
                      id: sessionId,
                      data: { isDictation: true }
                    })
                  }
                  setRecordingType('dictation')
                }}
              >
                Dictate session summary
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        <Box mb="xsmall" mt="xsmall" w="100%">
          <Menu matchWidth>
            <MenuButton
              px="small"
              borderRadius="md"
              borderWidth="1px"
              borderColor="light_gray"
              h="40px"
              w="100%"
            >
              <Flex justifyContent="space-between">
                {sessionType ? (
                  <Text>{sessionType}</Text>
                ) : (
                  <Flex>
                    <Text color="gray">Session Type</Text>
                    <Tooltip
                      label={`Audio is optomized to improve note quality based on your session setting.`}
                    >
                      <Flex ml="small" pointerEvents="auto" alignItems="center">
                        <InfoIcon h="20px" w="20px" fill="gray" />
                      </Flex>
                    </Tooltip>
                  </Flex>
                )}
                <SelectDown />
              </Flex>
            </MenuButton>
            <MenuList boxShadow="0 0 0 1px rgba(0, 0, 0, 0.1),0 4px 11px rgba(0, 0, 0, 0.1)">
              <MenuItem
                _focus={{ bg: '#F5F5F7' }}
                onClick={async () => {
                  // @ts-ignore
                  if (sessionId)
                    await patchSession({
                      id: sessionId,
                      data: { isTelehealth: true }
                    })
                  setSessionType('Telehealth')
                }}
              >
                Telehealth
              </MenuItem>
              <MenuItem
                _focus={{ bg: '#F5F5F7' }}
                onClick={async () => {
                  // @ts-ignore
                  if (sessionId)
                    await patchSession({
                      id: sessionId,
                      data: { isTelehealth: false }
                    })
                  setSessionType('In Person')
                }}
              >
                In Person
              </MenuItem>
            </MenuList>
          </Menu>
        </Box>
        {sessionType === 'Telehealth' && (
          <Box mb="xsmall" mt="xsmall">
            <IconSelect
              onChange={async (value: string) => {
                const isUsingHeadphones = value === 'headphones'
                setIsUsingHeadphones(isUsingHeadphones)
                if (sessionId)
                  await patchSession({
                    id: sessionId as string,
                    data: { isUsingHeadphones }
                  })
              }}
              selectedValue={isUsingHeadphones ? 'headphones' : 'noHeadphones'}
              options={[
                {
                  value: 'headphones',
                  title: 'Using headphones',
                  icon: <Headphones />
                },
                {
                  value: 'noHeadphones',
                  title: 'Not using headphones',
                  icon: <NoHeadsetIcon />
                }
              ]}
              height="40px"
            />
          </Box>
        )}
        {showSafariWarning && (
          <Box w="100%">
            <Alert status="error" bg="#EB5164" borderRadius="8px" p={4}>
              <Box mr={2} w="20px" h="20px">
                <InfoIcon fill="white" />
              </Box>
              <AlertDescription color="white">
                Important: Due to a limitation of the Safari browser, the AI
                Notetaker feature will not be able to capture participant audio
                duing a telehealth session. For the best results, please use
                Chrome.
              </AlertDescription>
            </Alert>
          </Box>
        )}
      </Box>
      <Flex w="100%" alignItems="center" flexDirection="column">
        {selectedClient && !settings.hidePreviousSessions && (
          <Text
            mt="auto"
            as="u"
            color="primary"
            _hover={{ cursor: 'pointer' }}
            onClick={() =>
              history.push(`/widget/patient/${selectedClient.value}/sessions`)
            }
          >
            View previous sessions
          </Text>
        )}
        <Divider mt="12px" />
        <Button
          aria-label="Start Recording"
          onClick={handleStartRecording}
          isFullWidth
          size="lg"
          borderRadius="4px"
          background="primary"
          maxWidth={maxWidth}
          mt="12px"
          m={0}
        >
          <HStack spacing={2}>
            <RadioSelectedIcon fill="#68E19F" />
            <Text fontWeight="bold" color="white">
              Start Recording
            </Text>
          </HStack>
        </Button>
      </Flex>
    </Flex>
  )
}
