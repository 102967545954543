import React, { useState, useEffect, useRef } from 'react'
import {
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuOptionGroup,
  MenuItemOption,
  Tabs,
  TabList,
  Tab,
  Box
} from '@chakra-ui/react'
import { ChevronDown } from '@components/icons'
import { keyBy } from 'lodash'
import { useExperienceManager } from '@hooks'

type Props = {
  optionValue: string
  onChange: (value: string | string[]) => void
  progressNoteTypes: any
}

export const NoteTypeDropdown = ({
  optionValue,
  onChange,
  progressNoteTypes
}: Props) => {
  const [selectedNoteOptions, setSelectedNoteOptions] = useState('therapists')
  const [isOpen, setIsOpen] = useState(false)
  const selectedRef = useRef<HTMLDivElement | null>(null)
  const { prescriberNoteTypeOptionsLabel } = useExperienceManager()

  useEffect(() => {
    setTimeout(() => {
      if (selectedRef.current) {
        selectedRef.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest'
        })
      }
    }, 300)
  }, [isOpen])

  const noteTypesById = keyBy(progressNoteTypes, 'id')
  const therapistNotes = progressNoteTypes.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'therapists'
  )

  const prescriberNotes = progressNoteTypes.filter(
    (o: any) => !o.hideFromDefaultOptions && o.noteGroup === 'prescribers'
  )

  useEffect(() => {
    const noteOptions = therapistNotes.find((n: any) => n.id === optionValue)
      ? 'therapists'
      : 'prescribers'
    setSelectedNoteOptions(noteOptions)
  }, [optionValue])

  const buttonRef = useRef<HTMLButtonElement>(null)
  const [menuWidth, setMenuWidth] = useState<number | undefined>(undefined)

  useEffect(() => {
    const updateWidth = () => {
      if (buttonRef.current) {
        setMenuWidth(buttonRef.current.offsetWidth)
      }
    }
    updateWidth()

    window.addEventListener('resize', updateWidth)

    return () => window.removeEventListener('resize', updateWidth)
  }, [])

  return (
    <Menu closeOnSelect onOpen={() => setIsOpen(!isOpen)}>
      <MenuButton
        ref={buttonRef}
        w="100%"
        as={Button}
        value={optionValue}
        border="1px solid rgb(228, 229, 230)"
        borderColor="#C9C9C9"
        m={0}
        color="black"
        background="white"
        borderRadius="6px"
        height="48px"
        paddingInlineStart={4}
        rightIcon={<ChevronDown />}
        textAlign="start"
      >
        {noteTypesById[optionValue as keyof typeof noteTypesById]?.display}
      </MenuButton>
      <MenuList
        paddingTop="0"
        maxHeight="240px"
        width={`${menuWidth}px`}
        overflowY="scroll"
        borderRadius="8px"
        zIndex={1000}
      >
        <Tabs
          isFitted
          index={selectedNoteOptions === 'therapists' ? 0 : 1}
          onChange={i =>
            setSelectedNoteOptions(i === 0 ? 'therapists' : 'prescribers')
          }
        >
          <TabList>
            <Tab
              height="50px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                Therapy Notes
              </Box>
            </Tab>
            <Tab
              height="50px"
              whiteSpace="nowrap"
              overflow="hidden"
              textOverflow="ellipsis"
            >
              <Box as="span" overflow="hidden" textOverflow="ellipsis">
                {prescriberNoteTypeOptionsLabel || 'Prescriber Notes'}
              </Box>
            </Tab>
          </TabList>
        </Tabs>
        <MenuOptionGroup
          defaultValue={optionValue}
          backgroundColor="primary"
          margin="0"
          color="white"
          height="50px"
          type="radio"
          paddingLeft="20px"
          alignItems="center"
          display="flex"
          onChange={onChange}
        >
          {selectedNoteOptions === 'therapists'
            ? therapistNotes.map((n: any, index: number) => (
                <MenuItemOption
                  key={`therapist-${n.id}-${index}`}
                  value={n.id}
                  paddingLeft="20px"
                >
                  <div ref={n.id === optionValue ? selectedRef : null}>
                    {n.display}
                  </div>
                </MenuItemOption>
              ))
            : prescriberNotes.map((n: any, index: number) => (
                <MenuItemOption
                  key={`prescriber-${n.id}-${index}`}
                  value={n.id}
                  paddingLeft="20px"
                >
                  <div ref={n.id === optionValue ? selectedRef : null}>
                    {n.display}
                  </div>
                </MenuItemOption>
              ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  )
}
